import "./Jobs.scss";

import React from "react";

export interface JobsProps {}

export const Jobs: React.FC<JobsProps> = ({}) => {
    return (
        <div className="Jobs">
            <iframe
                id="personio-iframe"
                style={{ border: "none" }}
                src="https://alphaneun.jobs.personio.de/"
                width="100%"
                onLoad={() => {
                    window.addEventListener(
                        "message",
                        function (e) {
                            const iframe =
                                document.querySelector("#personio-iframe");
                            const eventName = e.data[0];
                            const data = e.data[1];

                            if (iframe) {
                                switch (eventName) {
                                    case "setHeight":
                                        //@ts-ignore
                                        iframe.style.height = data + "px";
                                        break;
                                }
                            }
                        },
                        false,
                    );
                }}
            ></iframe>
        </div>
    );
};
