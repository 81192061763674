import "./page.scss";

import {
    ContainerSize,
    Headline,
    HeadlineType,
    Section,
    Stage,
    Teaser,
    TeaserSize,
    Text,
    TextType,
} from "@alphaneun/ui-library";
import { graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import React from "react";

import { Jobs } from "../components/job/components/jobs/Jobs";
import Layout from "../components/Layout";
import SEO from "../components/SEO";
import ContentBlock from "../types/contentblock";
import PrismicPage from "../types/page";
import useSiteMetadata from "../utils/useSiteMetadata";
import { Block } from "./articlepage";

const Page: React.FC<PrismicPage> = ({ data, location }: PrismicPage) => {
    const { url } = useSiteMetadata(),
        {
            meta_title,
            meta_description,
            meta_keywords,
            stage_size,
            feature_image,
            body,
            content,
            show_jobs,
        } = data.prismicPage.data;

    return (
        <Layout>
            <SEO
                title={meta_title}
                description={meta_description}
                url={`${url}${location.pathname}`}
                keywords={meta_keywords?.split(", ")}
            />

            <Stage
                size={stage_size}
                image={
                    <>
                        <GatsbyImage
                            alt=""
                            style={{ height: "100%" }}
                            // @ts-ignore
                            image={getImage(
                                feature_image?.localFile.childImageSharp
                                    .gatsbyImageData,
                            )}
                            loading="eager"
                        />

                        <div className="Page__gradient" />
                    </>
                }
            />

            <div className="Page__container">
                {content.length > 0 && (
                    <Section
                        containerSize={ContainerSize.Small}
                        contentClassName="BlogPost"
                    >
                        {content.map((block: ContentBlock, index: number) => (
                            <Block key={index} block={block} />
                        ))}
                    </Section>
                )}

                {body.map((section, index: number) => {
                    if (section.slice_type === "intro") {
                        return (
                            <Section
                                key={index}
                                className="Page__introSection"
                                showWarpAnimation={true}
                                showCloudAnimation={true}
                                cloudStyles={{
                                    transform: "scale(1.5)",
                                }}
                                warpStyles={{
                                    right: "-150px",
                                    top: "-200px",
                                    transform: "scale(1.4) rotate(10deg)",
                                    opacity: 0.5,
                                }}
                            >
                                <Headline type={HeadlineType.H1}>
                                    {section.primary.intro_headline}
                                </Headline>

                                <Text type={TextType.BodyHuge}>
                                    {section.primary.intro_text}
                                </Text>
                            </Section>
                        );
                    }

                    if (section.slice_type === "section___teasers") {
                        const {
                            section_title,
                            section_type,
                            section_headline_position,
                            teaser_image_position,
                            teaser_image,
                            teaser_text,
                            teaser_text_position,
                            teaser_title,
                            teaser_headline,
                        } = section.primary;

                        return (
                            <Section
                                type={section_type}
                                headline={section_title}
                                headlinePosition={section_headline_position}
                                key={index}
                            >
                                <Teaser
                                    size={TeaserSize.Full}
                                    headline={teaser_headline}
                                    title={teaser_title}
                                    imagePosition={teaser_image_position}
                                    image={
                                        <GatsbyImage
                                            alt=""
                                            style={{ height: "100%" }}
                                            // @ts-ignore
                                            image={getImage(
                                                teaser_image?.localFile
                                                    .childImageSharp
                                                    .gatsbyImageData,
                                            )}
                                        />
                                    }
                                    text={teaser_text?.richText
                                        .map((r) => r.text)
                                        .join("\n\n")}
                                    textPosition={teaser_text_position}
                                />
                            </Section>
                        );
                    }
                    return null;
                })}

                {show_jobs && <Jobs />}
            </div>
        </Layout>
    );
};

export default Page;

export const query = graphql`
    query PageQuery($id: String) {
        prismicPage(id: { eq: $id }) {
            uid
            last_publication_date
            _previewable
            data {
                meta_title
                meta_keywords
                meta_description
                show_jobs
                title
                feature_image {
                    localFile {
                        ...fullSizeImage
                    }
                }
                content {
                    navigation_title
                    text {
                        richText
                    }
                    image {
                        localFile {
                            ...constrainedImage
                        }
                    }
                }

                body {
                    ... on PrismicPageDataBodyIntro {
                        slice_type
                        primary {
                            intro_headline
                            intro_text
                        }
                    }
                    ... on PrismicPageDataBodySectionTeasers {
                        slice_type
                        primary {
                            section_headline_position
                            section_title
                            section_type
                            teaser_date
                            teaser_headline
                            teaser_headline_size
                            teaser_image {
                                localFile {
                                    ...teaserImage
                                }
                            }
                            teaser_image_position
                            teaser_link_text
                            teaser_link_url
                            teaser_pointer
                            teaser_text {
                                richText
                            }
                            teaser_text_position
                            teaser_title
                        }
                    }
                }
            }
        }
    }
`;
